<template>
  <div class="architecture">
    <jet-card :titleType="2" title="技术架构" subTitle="JetLinks是PaaS服务物联网平台，负责设备管理和协议数据管理，在物联网应用和设备之间搭建高效、稳定、安全的应用平台。" bgColor="transparent">
      <img src="@/assets/images/production/jetlinksiot/architecture.png" alt="">
    </jet-card>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .architecture{
    background:#FFFFFF;
    background-image: linear-gradient(#f2f2f2 1px,transparent 0),
    linear-gradient(90deg, #f2f2f2 1px,transparent 0),
    linear-gradient(hsla(0,0%,100%,.3) 1px,transparent 0),
    linear-gradient(90deg,hsla(0,0%,100%,.3) 1px,transparent 0);
    background-size:30px 30px,30px 30px,5px 5px,5px 5px;
  }
</style>