<template>
  <div class="jetlinks-iot">
    <Banner title="JetLinks IOT" subTitle="物联网平台" :label="['致力于帮助企业跳过设备接入层和数据处理层环境搭建直接实现自身应用平台开发，实现多种行业、多应用场景搭建，开放对接各类硬件.']">
      <img src="@/assets/images/production/bigdata.png">
    </Banner>
    <Feature></Feature>
    <Architecture></Architecture>
    <Version></Version>
    <QuickStart></QuickStart>
  </div>
</template>

<script>
import Banner from '@/components/production/Banner';
import Feature from '@/components/production/jetlinksiot/Feature';
import Architecture from '@/components/production/jetlinksiot/Architecture';
import Version from '@/components/production/jetlinksiot/Version';
import QuickStart from '@/components/production/jetlinksiot/QuickStart';
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    Banner,
    Feature,
    Architecture,
    Version,
    QuickStart
  },
  methods: {
    
  },
}
</script>
<style scoped>
  
</style>