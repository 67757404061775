<template>
  <div class="quick-start">
    <jet-card title="快速入门" bgColor="#F6F7FB" :titleType="2">
      <ul class="video-list">
        <li v-for="item, index in dataList" :key="index">
          <div>
            <video-player :url="item.url"></video-player>
            <router-link target="_blank" :to="{path: '/production/quick_access', query: {title: item.title,url: item.url, introduce: item.introduce}}"><div class="mask"></div></router-link>
          </div>
          <p>{{item.title}}</p>
        </li>
      </ul>
    </jet-card>
  </div>
</template>

<script>
import VideoPlayer from '@/components/common/VideoPlayer';
export default {
  name: '',
  data() {
    return {
      dataList: [
        {id: 0, title: '权限接入',  url: 'https://www.jetlinks.cn/authorization.mp4', introduce: '本教程指导您基于用户、角色、权限、菜单、组织五类管理功能实现对物联网平台的功能权限控制和数据权限控制操作。'},
        {id: 1, title: 'mqtt使用',  url: 'https://www.jetlinks.cn/mqttx-device-access.mp4', introduce: '本教程指导您使用MQTTX模拟设备并基于JetLinks官网协议、MQTT传输协议入物模拟设备到联网平台。'},
        // {id: 2, title: '规则引擎介绍',  url: 'https://media.w3.org/2010/05/sintel/trailer.mp4'},
        // {id: 3, title: '数据库支持',  url: 'https://media.w3.org/2010/05/sintel/trailer.mp4'},
        // {id: 4, title: '云云对接介绍',  url: 'https://media.w3.org/2010/05/sintel/trailer.mp4'},
      ]
    }
  },
  components: {
    VideoPlayer
  },
  methods: {

  },
}
</script>
<style scoped lang="less">
.quick-start{
  .video-list{
    margin-top: 2rem;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    color: #333333;
    font-weight: 700;
    font-size: 1.25rem;
    li{
      width: 33.33%;
      div{
        width: 23.3125rem;
        height: 13.1875rem;
        position: relative;
        .mask{
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          z-index: 2;
          cursor: pointer;
        }
      }
      p{
        margin-top: 1.5rem;
      }
    }
  }
}
</style>