<template>
  <div class="feature">
    <jet-card title="产品特性" :titleType="2">
      <ul class="column-list">
        <li>
          <div></div>
          <p>自研hsweb、easyorm、reactorQL、规则引擎、消息总线、注册中心等物联网平台核心组件。</p>
        </li>
        <li>
          <div></div>
          <p>基于自研组件与开放协议，可快速二次开发，适配各行业物联网业务。</p>
        </li>
        <li>
          <div></div>
          <p>支持InFluxDB、ClickHouse、Elasticsearch、Cassandra、TDengine五种时序数据库。</p>
        </li>
        <li>
          <div></div>
          <p>全响应式企业级物联网平台，面向数据流和变化传递，更优雅的多线程和异步编程方式。</p>
        </li>
        <li>
          <div></div>
          <p>支持可视化规则引擎编辑器，实现拖拉拽的方式处理数据输入、清洗、计算、输出、推送、存储。</p>
        </li>
        <li>
          <div></div>
          <p>支持鲲鹏等国产化服务器部署。</p>
        </li>
      </ul>
      <img src="@/assets/images/production/jetlinksiot/feature.png">
      <ul class="row-list">
        <li>
          <div></div>
          <p>MQTT/UDP/TCP/HTTP/WebSocket/CoAP/NB/MODBUS/OPC等全面的网络传输协议支持。</p>
        </li>
        <li>
          <div></div>
          <p>水平扩容架构，支持集群微服务部署 。</p>
        </li>
      </ul>
    </jet-card>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
       
    }
  },
  components: {
    
  },
  methods: {
    
  },
}
</script>
<style scoped lang="less">
  .feature{
    color: #666666;
    position: relative;
    li {
      display: flex;
      align-items: flex-start;
      margin-top: 1.875rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 1.375rem;
      border-radius: 6px;
      padding-left: 4rem;
      position: relative;
      div{
        margin-top: 0.2rem;
        margin-right: 1.3125rem;
        left: 2rem;
        width: 14px;
        height: 14px;
        position: absolute;
        background-color: #3055EA;
      }
      p{
        margin: 0 !important;
        color: #0B1145 !important;
        font-size: 1.125rem;
        word-wrap:break-word;
        word-break:break-all;
      }
    }
    .column-list {
      width: 48%;
    }
    .row-list {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      li{
        width: 48%;
      }
    }
    img{
      position: absolute;
      right: -5rem;
      top: 5rem;
    }
  }
</style>